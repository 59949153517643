.LinkTree {
  font-family: sans-serif;
  text-align: center;
}

.myLink {
  display: block;
  padding: 20px 15px;
  margin: 10px;
  background-color: white;
  border-radius: 50px;
  border: 1px solid black;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.myLink:hover {
  background-color: black;
  color: white;
}
