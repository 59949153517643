/* ProgressiveRomanticPage.css */
.progressive-page {
  font-family: "Merriweather", serif;
  text-align: center;
  padding: 50px;
  background: linear-gradient(
    to right,
    #f7b7d4,
    #ffc0cb
  ); /* Soft pink gradient */
  color: #333;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Soft white background for content */
  border-radius: 12px;
  padding: 40px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

button {
  background-color: #ff1493; /* Hot pink */
  border: none;
  color: white;
  padding: 12px 0;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%; /* Make buttons occupy full width */
  transition: 0.3s;
  font-family: "Merriweather", serif;
  margin-bottom: 15px; /* Space between buttons */
}

button:hover {
  background-color: #db0073;
}

.step {
  margin-bottom: 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.greeting {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.message {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-style: italic;
  color: #444;
}

.cry-popup {
  background: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea.message-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.submit-btn {
  background-color: #32cd32;
  border: none;
  color: white;
  padding: 12px 0;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%; /* Uniform width for the submit button */
  font-family: "Merriweather", serif;
}

.start-btn {
  background-color: #00bfff;
  width: 40%;
  padding: 2px;
  margin-top: 10px;
}

/* .yes-btn {
  background-color: #32cd32;
}

.no-btn {
  background-color: #ff6347;
} */

.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
